.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff12;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.leaflet-top.leaflet-right {
  max-height: 100%;
  pointer-events: auto;
  overflow: auto;
  z-index: 1000;
}

.leaflet-tile-pane .map {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.leaflet-layer.leaflet-interactive .leaflet-tile-container {
  pointer-events: auto !important;
}

.leaflet-pane.leaflet-contextmenu-pane {
  z-index: 1000 !important;
}

.leaflet-error {
  color: red;
  font-size: 1.25rem;
}

.leaflet-message {
  color: #0067be;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 1.25rem;
}

.leaflet-tecpopup .selected {
  font-weight: bold;
}

.App-header {
  background-color: #003968;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-row {
  display: flex;
}

.impc-attribution {
  width: 50px;
  height: 50px;
}

.legend {
  line-height: 18px;
  color: #555;
  background-color: #f7f7f7b5;
  padding: 5px;
  border: 0;
}

.legend td {
  border: 0;
  padding: 0;
  margin: 0;
}

.legend i {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  opacity: 0.7;
}
/* Hack to remove aA from plotly legend */
g.pointtext {
  display: none;
}
